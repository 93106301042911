@font-face {
  font-family: "PolySans-Median";
  src: local("PolySans"),
    url("./assets/fonts/PolySans-Median.otf") format("truetype");
}

@font-face {
  font-family: "PolySans-MedianItalic";
  src: local("PolySans"),
    url("./assets/fonts/PolySans-MedianItalic.otf") format("truetype");
}

@font-face {
  font-family: "PolySans-Neutral";
  src: local("PolySans"),
    url("./assets/fonts/PolySans-Neutral.otf") format("truetype");
}

@font-face {
  font-family: "PolySans-NeutralItalic";
  src: local("PolySans"),
    url("./assets/fonts/PolySans-NeutralItalic.otf") format("truetype");
}
